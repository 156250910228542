//
// bootstrap-typeahead.scss
//
.rbt-input-multi .rbt-input-main::placeholder {
    color: var(--ct-input-placeholder-color);
}
.rbt-input-multi .rbt-input-main {
    color: var(--ct-input-color)
}

